<template>
    <dashboard-layout>
        <v-row>
            <v-col>
                <h3>
                    Your track list
                </h3>
                <p class="text-caption">Add new tracks and edit existing ones</p>
            </v-col>
            <v-col class="text-right">
                <v-btn
                    :disabled="!creteBtnStatus"
                    :to="{name: 'artist.tracks.create'}">
                    Create
                </v-btn>
                <div v-if="!creteBtnStatus" class="text-subtitle-1">
                    Want to upload your track here? We'd all love to hear it! Just upgrade your account now and get
                    track uploads and so much more!
                    <router-link :to="{name: 'dashboard.billing.select-plan'}">Select Plan</router-link>
                </div>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-data-table
                    :loading="loading"
                    loading-text="Loading... Please wait"
                    :headers="headers"
                    :items="tracks"
                    :items-per-page="20"
                    class="elevation-1"
                >
                    <template v-slot:item.title="{ item }">
                        <router-link :to="{name: 'artist.tracks.show', params: {id: item.id}}">
                            {{ item.title }}
                        </router-link>
                    </template>
                    <template v-slot:item.created_at="{ item }">
                        {{ $date(item.created_at).format('MMM DD, YYYY HH:mm') }}
                    </template>
                    <template v-slot:item.actions="{ item }">
                        <v-btn color="red" @click="deleteTrack(item)">delete</v-btn>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>
    </dashboard-layout>
</template>

<script>
import Track from '@/models/Track';
import DashboardLayout from "@/layouts/DashboardLayout";
import {mapMutations} from "vuex";

export default {
    name: "users-index",
    components: {DashboardLayout,},
    computed: {
        creteBtnStatus() {
            if (this.loading) {
                return false;
            }

            if (this.tracks.length === 0) {
                return true
            }

            return this.can('haveManyTracks', 'artist')
        }
    },
    data: function () {
        return {
            loading: false,
            tracks: [],
            headers: [
                {
                    text: '# ID',
                    align: 'start',
                    sortable: false,
                    value: 'id',
                },
                {text: 'Title', value: 'title'},
                {text: 'Artist', value: 'artist.name'},
                {text: 'Created at', value: 'created_at'},
                {text: 'Actions', value: 'actions'},
            ],
        }
    },
    async mounted() {
        await this.fetchData()
    },
    methods: {
        async fetchData() {
            this.loading = true
            this.tracks = await Track.include('artist').get()
            this.loading = false
        },
        async deleteTrack(track) {
            let status
            let message

            try {
                status = (await track.delete())['data']['success']
                message = 'Track deleted.'
            } catch (e) {
                status = false
                message = e.response.data.message ?? 'Something went wrong. Please try again later.'
            }

            if (status) {
                this.tracks = this.$_.without(this.tracks, track)
            }

            this.showSnackBar({
                color: status ? 'success' : 'error',
                timeout: 3000,
                text: message
            })
        },
        ...mapMutations(['showSnackBar']),
    }
}
</script>

<style scoped>

</style>
